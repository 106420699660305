<template>
  <form @submit.prevent="handleSubmit">
    <h3 class="form__subtitle">{{ $t('yourPersonalInfoYourName') }}</h3>
    <div class="row">
      <div class="col form__col">
        <input-gcr label="firstname" invalidText="firstnamerequired" v-model="firstname" :submitted="submitted" />
      </div>
    </div>
    <div class="row">
      <div class="col form__col">
        <input-gcr label="lastname" invalidText="lastnamerequired" v-model="lastname" :submitted="submitted" />
      </div>
    </div>
    <div class="row">
      <div class="col form__col form__btn">
        <button-gcr
          :disabled="isConnectedAsSAC"
          btnClass="btn-primary btn-sm btn-arrow"
          label="updateprofile"
          :loading="updating"
        />
      </div>
    </div>
  </form>
</template>

<script>
import InputGcr from '@/components/atoms/Input'
import ButtonGcr from '@/components/atoms/Button'
import { Consts, Urls } from '@/_helpers'
import currentUserProfile from '@/graphql/users/getCurrentUserProfile.graphql'
import updateProfile from '@/graphql/users/updateProfile.graphql'
import userByEmail from '@/graphql/users/getUserByEmail.graphql'
import { mapGetters } from 'vuex'

require('../../utilities/form')

export default {
  components: { InputGcr, ButtonGcr },
  data() {
    return {
      firstname: '',
      lastname: '',
      communicationLanguage: 'FRENCH',
      submitted: false,
      updating: false
    }
  },
  props: {
    displaySacEmail: {
      type: Boolean,
      default: false
    },
    dirty : {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    getprofile: {
      query: currentUserProfile,
      skip() {
        return this.isConnectedAsSAC
      },
      update(data) {
        this.firstname = data.currentUser.firstName
        this.lastname = data.currentUser.lastName
        this.communicationLanguage = data.currentUser.communicationLanguage
      }
    },
    getuserByEmail: {
      query: userByEmail,
      variables: function() {
        return {
          email: this.emailSAC
        }
      },
      skip() {
        return !this.isConnectedAsSAC
      },
      update(data) {
        this.firstname = data.userByEmail.firstName
        this.lastname = data.userByEmail.lastName
        this.communicationLanguage = data.userByEmail.communicationLanguage
      }
    }
  },
  computed: {
    ...mapGetters({
      isSAC: Consts.GETTER_USER_ISSAC,
      emailSAC: Consts.GETTER_USER_EMAILSAC
    }),
    isConnectedAsSAC() {
      return this.isSAC && !this.displaySacEmail
    },
  },
  methods: {
    handleSubmit() {
      this.submitted = true

      if (!this.firstname || !this.lastname) {
        return
      }

      this.updating = true
      this.$apollo
          .mutate({
            mutation: updateProfile,
            variables: {
              profile: {
                firstName: this.firstname,
                lastName: this.lastname,
                communicationLanguage: this.communicationLanguage
              }
            }
          })
          .then(data => {
            this.$emit('update:dirty', false)
            this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_INFO, text: 'userProfilSaveSucced' })
            this.$router.push(Urls.localizedUrl(Urls.DASHBOARD))
          })
          .finally(() => {
            this.updating = false
          })
    }
  }
}
</script>
