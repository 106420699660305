<template>
  <form @submit.prevent="changePassword">
    <h3 class="form__subtitle">{{ $t('yourPersonalInfoPassword') }}</h3>
    <div class="row">
      <div class="col form__col">
        <p>{{ $t('changePasswordDesc1') }}</p>
        <p>{{ $t('changePasswordDesc2') }}</p>
        <password-gcr
          label="actualpassword"
          type="password"
          invalidText="passwordrequired"
          v-model="actualpassword"
          :submitted="submitted"
        />
      </div>
    </div>
    <div class="row">
      <div class="col form__col">
        <p>
          <strong>{{ $t('yourPersonalInfoSecurityLevel') }}</strong
          ><br />{{ $t('createnewpasswordtext') }}
        </p>
        <password-gcr
          label="newpassword"
          type="password"
          invalidText="passwordrequired"
          v-model="newpassword"
          :submitted="submitted"
        />
      </div>
    </div>
    <div class="row">
      <div class="col form__col form__btn">
        <button-gcr
          :disabled="readOnly"
          btnClass="btn-primary btn-sm btn-arrow"
          label="changepassword"
          :loading="updating"
        />
      </div>
    </div>
  </form>
</template>

<script>
import PasswordGcr from '@/components/atoms/Password'
import ButtonGcr from '@/components/atoms/Button'
import { Consts, Validations } from '@/_helpers'
import changePassword from '@/graphql/users/changePassword.graphql'
import { mapGetters } from 'vuex'

export default {
  components: { PasswordGcr, ButtonGcr },
  data() {
    return {
      actualpassword: '',
      newpassword: '',
      submitted: false,
      updating: false
    }
  },
  props: {
    displaySacEmail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isSAC: Consts.GETTER_USER_ISSAC,
      emailSAC: Consts.GETTER_USER_EMAILSAC
    }),
    readOnly() {
      return this.isSAC && !this.displaySacEmail
    }
  },
  methods: {
    changePassword() {
      this.submitted = true

      if (this.actualpassword && this.newpassword) {
        if (!Validations.validePassword(this.newpassword)) {
          this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'newPasswordInvalid' })
        } else {
          this.updating = true

          this.$apollo
            .mutate({
              mutation: changePassword,
              variables: {
                change: {
                  current: this.actualpassword,
                  new: this.newpassword
                }
              }
            })
            .then(data => {
              this.updating = false
              this.submitted = false
              this.actualpassword = ''
              this.newpassword = ''
              this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_INFO, text: 'changePasswordSucced' })
            })
            .catch(error => {
              this.updating = false
            })
        }
      }
    }
  }
}
</script>
