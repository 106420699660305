<template>
  <div class="form">
    <div class="form__header row" :class="{ 'form__header--sticky': isSticky }">
      <div class="form__header__back col-md-2 col-xs-10 offset-xs-1 offset-md-0">
        <router-link v-bind:to="dashboardUrl" class="form__header__back__link form__header__back__link--arrow">{{
          $t('goBack')
        }}</router-link>
      </div>
      <div class="form__header__main col-12 col-xs-10 offset-xs-1 offset-md-0">
        <h1 class="form__header__title">{{ $t('yourPersonalInfo') }}</h1>
      </div>
    </div>
    <div class="form__body row">
      <div class="form__container col-xs-10 col-sm-8 col-md-6 offset-xs-1 offset-md-2">
        <user-profile-form :display-sac-email="userIsSac" :dirty.sync="dirty"></user-profile-form>
        <change-password-form :display-sac-email="userIsSac"></change-password-form>
      </div>
    </div>
  </div>
</template>

<script>
import UserProfileForm from '@/components/organism/UserProfileForm'
import ChangePasswordForm from '@/components/organism/ChangePasswordForm'
import { Urls } from '@/_helpers'
import form from '@/components/mixins/form'

require('../utilities/form')

export default {
  components: { UserProfileForm, ChangePasswordForm },
  mixins: [form],
  data() {
    return {
      dashboardUrl: Urls.localizedUrl(Urls.DASHBOARD)
    }
  },
  props: {
    userIsSac: {
      type: Boolean,
      default: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeave(to, from, next)
  }
}
</script>
